import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import _ from "lodash";
import { getContrastText } from "../utils";
import { Popover } from "react-tiny-popover";
import useStats from "../hooks/useStats";
import logoPlaceholder from "../images/no-image_baseball.png";

const GameContainer = styled.div`
  ${tw`flex flex-col w-full flex-1 h-full relative p-1`}
  box-shadow: 
    -1px 0 0 0 #999999, 
    0 -1px 0 0 #999999, 
    1px 0 0 0 #999999, 
    0 1px 0 0 #999999;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => getContrastText(props.backgroundColor)};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
`;

const MiniGameContainer = styled.div`
  ${tw`flex flex-col w-full h-full justify-center relative pb-1.5`}
  box-shadow: 
    -1px 0 0 0 #999999, 
    0 -1px 0 0 #999999, 
    1px 0 0 0 #999999, 
    0 1px 0 0 #999999;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => getContrastText(props.backgroundColor)};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
`;

const LogoContainer = styled.div`
  ${tw`w-10 h-8`}
  ${({ noTopMargin }) => (noTopMargin ? tw`mt-0 mr-1` : tw`mx-auto mt-2`)}
`;
const GameTextLink = styled(Link)`
  ${tw`font-bold text-xs text-center hover:underline mx-auto text-ellipsis overflow-hidden whitespace-nowrap mt-2`}
  width: ${({ width }) => `${width}px`};
`;
const GameText = styled.div`
  ${tw`font-bold text-sm text-center hover:underline mx-auto text-ellipsis overflow-hidden whitespace-nowrap`}
  width: ${({ width }) => `${width}px`};
`;
const GameTime = styled.div`
  ${tw`text-center text-xs mt-1`}
`;
const GameActionContainer = styled.div`
  ${tw`flex flex-col mt-auto mb-1`}
`;
const GameLink = styled.a`
  ${tw`block relative text-center bg-black text-white mx-1 rounded py-0.5 font-bold text-xs uppercase`}
`;

const PopOverContent = styled.div`
  ${tw`w-[240px] h-[240px] border border-black`}
`;

const PopOverMiniContent = styled.div`
  ${tw`w-[260px] h-[85px] border border-black`}
`;
const MediaContainer = styled.div`
  ${tw`text-sm mx-2`}
`;

const DescriptionContainer = styled.div`
  ${tw`text-xs pb-1 overflow-y-auto`}
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 10px;
    border: 1px solid #cfcfcf;
  }
`;
const DescriptionHeader = styled.div`
  ${tw`flex text-xs font-bold items-center mt-2 ml-2`}
`;
const DescriptionList = styled.ul`
  ${tw`py-0.5`}
`;
const DescriptionItem = styled.li`
  ${tw`ml-7 text-[10]`}
`;
const PopoverGameLink = styled.a`
  ${tw`flex justify-center bg-black text-white mx-1 rounded py-1 font-bold text-sm uppercase`}
`;

const PopoverLogoContainer = styled.div`
  ${tw`w-14 h-12`}
  ${({ noTopMargin }) => (noTopMargin ? tw`mt-0 mr-2` : tw`mx-auto mt-2`)}
`;
const PopoverGameTextLink = styled(Link)`
  ${tw`font-bold text-base text-center hover:underline mt-2`}
`;
const MiniContainer = styled.div`
  ${tw`flex items-center justify-around`}
`;
const PopoverMiniLogoContainer = styled.div`
  ${tw`w-14 h-12 mx-2`}
`;
const PopoverMiniGameTextLink = styled(Link)`
  ${tw`block font-bold text-sm text-center w-full hover:underline`}
`;
const PopoverMiniFieldName = styled.div`
  ${tw`text-[11px] text-center`}
`;
const PopoverGameText = styled.div`
  ${tw`font-bold text-base text-center text-ellipsis overflow-hidden`}
  width: ${({ width }) => `${width}px`};
`;
const PopoverGameTime = styled.div`
  ${tw`text-center text-sm relative`}
`;
const PopoverLocation = styled.div`
  ${tw`text-center text-xs`}
`;
const CompleteGameContainer = styled.div`
  ${tw`flex flex-col mt-2 mx-1`}
`;
const TeamContainer = styled.div`
  ${tw`flex flex-row items-center justify-center`}
`;
const ScoreContainer = styled.div`
  ${tw`font-bold`}
`;
const PopoverActionSvg = styled.img`
  ${tw`w-4 h-4 absolute top-1.5 left-6`}
`;
const GameContainerLink = styled(Link)`
  ${tw`flex flex-col w-full flex-1 h-full relative p-1`}
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => getContrastText(props.backgroundColor)};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
`;

const ActionSvg = styled.img`
  ${tw`w-4 h-4 absolute left-6 top-1.5`}
`;
const DateContainer = styled.div`
  ${tw`absolute left-1 top-1 text-sm`}
  color: ${(props) => props.color};
`;
const PlacholderContainer = styled.div``;

// fix the black square issue
if (typeof window !== "undefined") {
  // Only run in browser environment
  const style = document.createElement("style");
  style.innerHTML = `
    .gatsby-image-wrapper [data-placeholder-image] {
      opacity: 0 !important;
      transition: none !important;
    }
    
    .gatsby-image-wrapper [data-main-image] {
      opacity: 1 !important;
      transition: none !important;
    }
  `;
  document.head.appendChild(style);
}

const PopoverManager = (function () {
  let currentOpen = null;
  const listeners = new Set();

  return {
    open: (id) => {
      if (currentOpen && currentOpen !== id) {
        listeners.forEach((listener) => listener(currentOpen));
      }
      currentOpen = id;
    },
    close: (id) => {
      if (currentOpen === id) {
        currentOpen = null;
      }
    },
    subscribe: (listener) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
})();

const CalendarGameItem = ({
  backgroundColor,
  striped,
  promoColor,
  opponentTeam,
  slug,
  isHomeGame,
  isCompletedGame,
  formattedTime,
  game,
  color,
  displayDate,
  homeTeamDoc,
  awayTeamDoc,
  site,
  showMiniVersion = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    const unsubscribe = PopoverManager.subscribe((openId) => {
      if (openId !== slug && isOpen) {
        setIsOpen(false);
      }
    });

    return unsubscribe;
  }, [slug, isOpen]);

  const handleMouseEnter = () => {
    setIsOpen(true);
    PopoverManager.open(slug);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
    PopoverManager.close(slug);
  };

  const getPosition = (props) => {
    const { childRect, parentRect } = props;
    return {
      top: showMiniVersion
        ? childRect.top - parentRect.top - 15
        : childRect.top - parentRect.top - 60,
      left: showMiniVersion
        ? childRect.left - parentRect.left - 60
        : childRect.left - parentRect.left - 60,
    };
  };

  const {
    promoGame,
    tv,
    radio,
    promoDescriptions,
    stats,
    showBuyTicket,
    field,
  } = game.node.frontmatter;
  const { score } = useStats({ inningStats: stats.inningStats });
  const { location } = homeTeamDoc.node.frontmatter;

  const awayLogo = getImage(_.get(awayTeamDoc, "node.frontmatter.logo"));
  const homeLogo = getImage(_.get(homeTeamDoc, "node.frontmatter.logo"));
  const opponentLogo = getImage(_.get(opponentTeam, "node.frontmatter.logo"));
  const currentTeamDoc = isHomeGame ? homeTeamDoc : awayTeamDoc;
  const currentTeamLogo = getImage(
    _.get(currentTeamDoc, "node.frontmatter.logo")
  );

  // Helper function to render Gatsby images consistently
  const renderGatsbyImage = (image, alt) => {
    if (image) {
      return (
        <div
          className="image-wrapper"
          style={{ width: "100%", height: "100%" }}
        >
          <GatsbyImage
            image={image}
            alt={alt}
            style={{ width: "100%", height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            objectFit="contain"
            loading="eager"
            fadeIn={false}
          />
        </div>
      );
    } else {
      return (
        <PlacholderContainer>
          <img
            src={logoPlaceholder}
            alt="placeholder"
            style={{ width: "100%", height: "auto" }}
            loading="eager"
          />
        </PlacholderContainer>
      );
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      contentLocation={getPosition}
      reposition={true}
      content={
        showMiniVersion ? (
          <PopOverMiniContent
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
          >
            <GameContainerWrapper
              backgroundColor={backgroundColor}
              striped={striped}
              promoColor={promoColor}
              slug={slug}
              isCompletedGame={isCompletedGame}
            >
              <MiniContainer>
                <PopoverMiniLogoContainer>
                  {renderGatsbyImage(currentTeamLogo, "opponent")}
                </PopoverMiniLogoContainer>
                <div>
                  <PopoverMiniGameTextLink to={slug}>
                    {currentTeamDoc.node.frontmatter.location.abbreviation}
                    {isHomeGame ? " VS " : " AT "}{" "}
                    {opponentTeam.node.frontmatter.location.abbreviation}{" "}
                  </PopoverMiniGameTextLink>
                  <PopoverGameTime>
                    {isCompletedGame ? "FINAL" : formattedTime}
                  </PopoverGameTime>
                  <PopoverMiniFieldName>{field}</PopoverMiniFieldName>
                </div>
                <PopoverMiniLogoContainer>
                  {renderGatsbyImage(opponentLogo, "current team")}
                </PopoverMiniLogoContainer>
              </MiniContainer>
            </GameContainerWrapper>
          </PopOverMiniContent>
        ) : (
          <PopOverContent
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
          >
            <GameContainerWrapper
              backgroundColor={backgroundColor}
              striped={striped}
              promoColor={promoColor}
              slug={slug}
              isCompletedGame={isCompletedGame}
            >
              <>
                <DateContainer color={color}>{displayDate}</DateContainer>
                {promoGame && (
                  <PopoverActionSvg
                    src="/img/svg/asterisk.svg"
                    alt="promo_game"
                  />
                )}
                {isCompletedGame ? (
                  <CompleteGameContainer>
                    <TeamContainer>
                      <PopoverGameText width={100}>
                        {awayTeamDoc.node.frontmatter.name}
                      </PopoverGameText>
                      <PopoverLogoContainer noTopMargin={true}>
                        {renderGatsbyImage(awayLogo, "awayteam")}
                      </PopoverLogoContainer>
                      <ScoreContainer>{score.away}</ScoreContainer>
                    </TeamContainer>
                    <TeamContainer>
                      <PopoverGameText width={100}>
                        {homeTeamDoc.node.frontmatter.name}
                      </PopoverGameText>
                      <PopoverLogoContainer noTopMargin={true}>
                        {renderGatsbyImage(homeLogo, "hometeam")}
                      </PopoverLogoContainer>
                      <ScoreContainer>{score.home}</ScoreContainer>
                    </TeamContainer>
                  </CompleteGameContainer>
                ) : (
                  <>
                    <PopoverLogoContainer>
                      {renderGatsbyImage(opponentLogo, "opponent")}
                    </PopoverLogoContainer>
                    {site === "dawgs" && (
                      <PopoverGameTextLink to={slug}>
                        {isHomeGame ? "vs. " : "@ "}{" "}
                        {opponentTeam.node.frontmatter.name}
                      </PopoverGameTextLink>
                    )}
                    {site === "academy" && (
                      <PopoverGameTextLink to={slug}>
                        {currentTeamDoc.node.frontmatter.name}
                        {isHomeGame ? " VS " : " AT "}{" "}
                        {opponentTeam.node.frontmatter.name}{" "}
                      </PopoverGameTextLink>
                    )}
                  </>
                )}
                <PopoverGameTime>
                  {isCompletedGame ? "FINAL" : formattedTime}
                </PopoverGameTime>
                {location && (
                  <PopoverLocation>
                    {location.town}{" "}
                    {location.fieldName && ` - location.fieldName`}
                  </PopoverLocation>
                )}
                {tv && (
                  <MediaContainer>
                    <b>TV:</b> {tv}
                  </MediaContainer>
                )}
                {radio && (
                  <MediaContainer>
                    <b>Radio:</b> {radio}
                  </MediaContainer>
                )}
                {promoGame && !_.isEmpty(promoDescriptions) && (
                  <DescriptionContainer>
                    {!_.isEmpty(promoDescriptions) && (
                      <DescriptionHeader>Promotions</DescriptionHeader>
                    )}
                    <DescriptionList>
                      {(promoDescriptions || []).map((str, idx) => (
                        <DescriptionItem key={idx}>{str}</DescriptionItem>
                      ))}
                    </DescriptionList>
                  </DescriptionContainer>
                )}
                {site === "dawgs" && (
                  <GameActionContainer>
                    {!isCompletedGame && (isHomeGame || showBuyTicket) && (
                      <PopoverGameLink
                        href={game.node.frontmatter.ticketLink || "#"}
                        target="_blank"
                      >
                        Buy Tickets
                      </PopoverGameLink>
                    )}
                  </GameActionContainer>
                )}
              </>
            </GameContainerWrapper>
          </PopOverContent>
        )
      }
    >
      {showMiniVersion ? (
        <MiniGameContainer
          backgroundColor={backgroundColor}
          promoColor={promoColor}
          striped={striped}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <GameTextLink to={slug} width={125}>
            {currentTeamDoc.node.frontmatter.location.abbreviation}
            {isHomeGame ? " VS " : " AT "}{" "}
            {opponentTeam.node.frontmatter.location.abbreviation}
          </GameTextLink>
        </MiniGameContainer>
      ) : (
        <GameContainer
          backgroundColor={backgroundColor}
          promoColor={promoColor}
          striped={striped}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {promoGame && (
            <ActionSvg src="/img/svg/asterisk.svg" alt="promo_game" />
          )}
          {isCompletedGame ? (
            <CompleteGameContainer>
              <TeamContainer>
                <GameText width={70}>
                  {awayTeamDoc.node.frontmatter.name}
                </GameText>
                <LogoContainer noTopMargin={true}>
                  {renderGatsbyImage(awayLogo, "awayteam")}
                </LogoContainer>
                <ScoreContainer>{score.away}</ScoreContainer>
              </TeamContainer>
              <TeamContainer>
                <GameText width={70}>
                  {homeTeamDoc.node.frontmatter.name}
                </GameText>
                <LogoContainer noTopMargin={true}>
                  {renderGatsbyImage(homeLogo, "hometeam")}
                </LogoContainer>
                <ScoreContainer>{score.home}</ScoreContainer>
              </TeamContainer>
            </CompleteGameContainer>
          ) : (
            <>
              <LogoContainer>
                {renderGatsbyImage(opponentLogo, "opponent")}
              </LogoContainer>
              {["dawgs", "academy"].includes(site) && (
                <GameTextLink to={slug} width={125}>
                  {isHomeGame ? "vs. " : "@ "}{" "}
                  {opponentTeam.node.frontmatter.name}
                </GameTextLink>
              )}
              {/* {site === "academy" && (
              <GameTextLink to={slug} width={100}>
                {homeTeamDoc.node.frontmatter.name} {"VS"}{" "}
                {awayTeamDoc.node.frontmatter.name}
              </GameTextLink>
            )} */}
            </>
          )}
          <GameTime>{isCompletedGame ? "FINAL" : formattedTime}</GameTime>
          {site === "dawgs" && (
            <GameActionContainer>
              {!isCompletedGame && (isHomeGame || showBuyTicket) && (
                <GameLink
                  href={game.node.frontmatter.ticketLink || "#"}
                  target="_blank"
                >
                  Buy Tickets
                </GameLink>
              )}
            </GameActionContainer>
          )}
        </GameContainer>
      )}
    </Popover>
  );
};

export default CalendarGameItem;

const GameContainerWrapper = ({
  backgroundColor,
  striped,
  promoColor,
  isCompletedGame,
  slug,
  children,
}) => {
  if (isCompletedGame) {
    return (
      <GameContainerLink
        to={slug}
        backgroundColor={backgroundColor}
        striped={striped}
        promoColor={promoColor}
      >
        {children}
      </GameContainerLink>
    );
  } else {
    return (
      <GameContainer
        backgroundColor={backgroundColor}
        striped={striped}
        promoColor={promoColor}
      >
        {children}
      </GameContainer>
    );
  }
};
